.bookmark-container {
  fill: #fff;
  position: relative;
}

.bookmark span {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: rgb(236, 83, 83);
  color: #fff;
  font-size: 1.2rem;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}
.bookmark svg {
  font-size: 2.5rem;
  fill: white;
  cursor: pointer;
}
.bookmark-collapse {
  --width: 100%;
  visibility: hidden;
  background-color: #fff;
  display: none;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.125);
  min-width: var(--width);
  max-height: 70vh;
  overflow-y: auto;
  padding: 2rem 3rem;
  z-index: 1000;
  position: fixed;
  left: 0;
}

@media (min-width: 768px) {
  .bookmark-collapse {
    --width: 400px;
    border-radius: 5px;
    position: absolute;
    left: calc(var(--width) * -1);
  }
}

.bookmark-collapse.show {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  visibility: visible;
}
.bookmark-mv-cover {
  height: 110px;
  border-radius: 5px;
  cursor: pointer;
}

.bookmark-collapse ul li {
  display: flex;
  gap: 1rem;
  padding: 1.5rem 0;
  cursor: pointer;
}
.bookmark-collapse ul li.no-bookmark {
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: rgb(180, 180, 180);
}
.bookmark-collapse ul li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.bookmark-detail {
  position: relative;
  width: 100%;
}
.bookmark-detail a,
.bookmark-detail a:visited,
.bookmark-detail a:link {
  text-decoration: none;
  color: #222222;
  font-size: 1.5rem;
  font-weight: 300;
  display: block;
  margin-bottom: 0.8rem;
  transition: all 250ms;
}
.bookmark-detail a:hover {
  color: var(--cl-secondary);
}
.bookmark-detail p {
  font-size: 1.2rem;
}
.bookmark-detail span {
  border: 1px solid rgb(229 151 151);
  background-color: rgb(255 184 184);
  border-radius: 2px;
  padding: 4px 8px;
  color: #680808;
  position: absolute;
  bottom: 0;
  right: 0;
}
.bookmark-btn-clear {
  padding: 1rem 0;
  border: none;
  border-radius: 5px;
  background-color: var(--cl-primary);
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.07em;
  cursor: pointer;
}

@media (min-width: 768px) {
  .bookmark-collapse {
    max-width: 500px;
  }
}
