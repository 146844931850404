footer {
  height: 250px;
  width: 100%;
  background-color: var(--cl-primary);
}

footer .container {
  padding: 2rem;
}

footer .footer {
  height: 90%;
  display: flex;
  width: clamp(200px, 100%, 600px);
  margin: 0 auto;
}
footer .footer .brand-name {
  flex: 1;
}
footer .footer ul {
  margin-left: 5rem;
  flex: 3;
  list-style: none;
}

footer .footer ul li {
  line-height: 30px;
}

footer .brand-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .brand-container a {
  font-size: 3rem;
  padding: 0.4em 0;
}

footer .copyright {
  text-align: center;
  font-size: 1.1rem;
  color: #dddd;
}
