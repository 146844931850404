.rate__score {
  width: 50px;
  height: 50px;
  transform: scale(0.9);
}

.rate__score--percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.2rem;
}

.rate__score--circle {
  background-color: rgba(0, 0, 0, 0.9);
  width: inherit;
  height: inherit;
  border-radius: 100%;
}

.rate__score--circle circle {
  fill: none;
  transform: translate(5px, 5px);
  stroke-width: 4;
  stroke-dasharray: 125;
}

.rate__score--circle circle:nth-child(1) {
  stroke: rgba(255, 255, 255, 0.25);
}
.rate__score--circle circle:nth-child(2) {
  /* stroke: rgb(133, 247, 133); */
  stroke-linecap: round;
  /* stroke-dashoffset: calc(125 - (125 * 83) / 100); */
}
