nav {
  width: 100%;
  height: var(--navbar-height);
  background-color: hsl(224, 50%, 31%);
}
nav ul {
  list-style: none;
}
.navbar {
  height: inherit;
  display: flex;
  align-items: center;
}

.nav {
  flex: 2;
  margin-left: 4rem;
  display: inline-flex;
  font-size: 1.5rem;
  list-style: none;
}

.nav li {
  padding: 0 0.8rem;
  color: #fff;
  cursor: pointer;
}
.nav li a {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  color: hsl(0deg 0% 100%);
}
