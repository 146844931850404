.loading {
  width: 100%;
  height: 30vh;
  display: grid;
  place-items: center;
  font-size: 4rem;
  font-weight: bold;
  color: rgb(130, 130, 130);
  opacity: 0.75;
  animation: loading 750ms linear infinite alternate;
}

@keyframes loading {
  0% {
    color: hsl(0, 0%, 51%);
  }
  100% {
    color: hsl(0, 0%, 80%);
  }
}
