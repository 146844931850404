.search-form-container {
  margin: 3rem auto;
  max-width: 600px;
}

.search {
  gap: 6rem;
}
.search__results {
  display: block;
}
.search__item {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .search__item {
    flex-direction: row;
  }
}
.search__item-info {
  flex: 1;
  align-self: self-start;
  padding: 3rem;
  border-radius: 4px;
  background-color: #fff;
}
.search__item-title {
  font-size: var(--font-1);
  line-height: 2.5rem;
  cursor: pointer;
}
.search__item-date {
  font-size: var(--font--1);
  line-height: 3rem;
  color: rgb(133, 131, 131);
}
.search__item-overview {
  font-size: var(--font-0);
}
.search__item-image {
  height: 220px;
  width: 180px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}
