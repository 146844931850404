.pagination {
  width: 100%;
  display: flex;
  gap: 1rem;
  list-style: none;
  font-size: 1.5rem;
  margin: 4rem 0;
}

.pagination .pagination__button {
  cursor: pointer;
  background-color: rgb(180 180 180 / 34%);
  padding: 1rem 2rem;
  border-radius: 10px;
  color: rgb(80 80 80);
  font-weight: bold;
}
.pagination .pagination__button.active {
  background-color: var(--cl-primary);
  color: #fff;
}
