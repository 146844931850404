#cast {
  margin: 2rem auto;
}

.cast {
  margin-top: 2rem;
  display: flex;
  gap: 0.8rem;
  overflow-y: hidden;
  height: 300px;
}

.cast__item {
  text-align: center;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.125);
  height: 280px;
  border-radius: 10px;
  width: 140px;
}
.cast__item img {
  height: 200px;
  width: 140px;
  border-radius: 5px;
}

.cast__item h2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 30px;
}

.cast__item p {
  font-size: 1.2rem;
  font-weight: 300;
}
